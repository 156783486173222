import { memo, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

import ButtonLogout from '../../components/ButtonLogOut';
import MainPage from '../../components/MainPage';
import productsAPI from '../../../api/products';
import LoadingData from '../../components/LoadingData';

function AdminProductsPage() {
	const [listProducts, setListProducts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			const res = await productsAPI.getAll();
			if (res.data) {
				setListProducts(res.data);
			}
			setLoading(false);
		})();
	}, []);

	return (
		<MainPage>
			<section>
				<ButtonLogout />
				<h1>商品一覧</h1>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>商品名</th>
							<th>数量</th>
							<th>小計</th>
							<th>概算重量</th>
							<th>確定重量</th>
							<th>商品URL</th>
							<th>注文番号</th>
							<th>注文日</th>
							<th>お客様名</th>
							<th>PACK NO</th>
							<th>ステータス</th>
						</tr>
					</thead>
					<tbody>
						{listProducts.map(item => (
							<tr key={item?._id}>
								<td>{item?.name}</td>
								<td>{item?.quantity}</td>
								<td>{item?.totalPrice}</td>
								<td>{item?.estimatedWeight}</td>
								<td>{item?.fixedWeight}</td>
								<td className="td-style">
									<a
										href={item?.url}
										target="_blank"
										rel="noreferrer"
									>
										{item?.url}
									</a>
								</td>
								<td>{item?.orderNo}</td>
								<td>
									{new Date(
										item?.orderDate,
									).toLocaleDateString('ja-JP')}
								</td>
								<td>{item?.customerName}</td>
								<td>{item?.pack?.packNo}</td>
								<td>{item?.status}</td>
							</tr>
						))}
					</tbody>
				</Table>
				<LoadingData active={loading} />
			</section>
		</MainPage>
	);
}

export default memo(AdminProductsPage);
