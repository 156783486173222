import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useDateEmpty } from '../../../../hooks/useDate';
import { Form, Button } from 'react-bootstrap';
import {
	listStatus,
} from '../../../../constants/statusPack';

import { shipType } from '../../../../constants/orderConst';
import orderAPI from '../../../../api/order';
import styles from '../AdminInfoOrder.module.scss';

function FormInput({ data }) {
	const dateEmpty = useDateEmpty;
	const { id } = useParams();
	const checkInput = text => {
		return text ? text : '';
	};

	const handlePushNotify = async role => {
		try {
			const res = await orderAPI.pushNotify(role);
			if (res.status === 200) {
				toast.success('PUSH通知を送信しました。');
			}
		} catch (err) {}
	};

	const handleChangeMethod = e => {
		const key = e.target.name;
		const value = e.target.value;

		(async () => {
			try {
				await orderAPI.update(id, { [key]: value });
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};

	return (
		<div>
			<Form>
				<div className="row">
					<div className="col col-lg-8">
						<div className="row">
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>注文番号</Form.Label>
									<Form.Control
										type="text"
										name="orderNo"
										onChange={handleChangeMethod}
										defaultValue={checkInput(data.orderNo)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>注文日</Form.Label>
									<Form.Control
										type="text"
										name="orderDate"
										onChange={handleChangeMethod}
										defaultValue={dateEmpty(data.orderDate)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>
										配送代行/購入代行
									</Form.Label>
									<Form.Select
										name="orderType"
										onChange={handleChangeMethod}
									>
										<option value={'PURCHASE_ORDER'} selected={'PURCHASE_ORDER' == data.orderType}>
											{shipType.PURCHASE_ORDER}
										</option>
										<option value={'SHIP_ORDER'} selected={'SHIP_ORDER' == data.orderType}>
											{shipType.SHIP_ORDER}
										</option>
									</Form.Select>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Label>
									注文ステータス
								</Form.Label>
								<Form.Select
									name="status"
									aria-label="Default select example"
									onChange={handleChangeMethod}
								>
									{listStatus.map(
										(item, index) =>
											index !== 0 && (
												<option
													key={index}
													value={item.value}
													selected={item.value == data.status}
												>
													{item.text}
												</option>
											),
									)}
								</Form.Select>
							</div>
						</div>
					</div>
					<div className="col col-lg-4">
						<div className="row">
							<Form.Label>
								PUSH通知送付ボタン
							</Form.Label>
							<div className={clsx(styles.buttonGroup)}>
								<Button
									variant="success"
									onClick={() =>
										handlePushNotify(
											`notification-make-parchase-bill/${id}`,
										)
									}
								>
									購入明細送付 
								</Button>
								{/* <Button
									variant="success"
									onClick={() =>
										handlePushNotify(
											`notification-finish-weight-measuremen/${id}`,
										)
									}
								>
									計量完了 
								</Button> */}
								<Button
									variant="success"
									onClick={() =>
										handlePushNotify(
											`notification-arrived-in-japan/${id}`,
										)
									}
								>
									日本オフィス到着時
								</Button>
								<Button
									variant="success"
									onClick={() =>
										handlePushNotify(
											`notification-shipping-to-hanoi/${id}`,
										)
									}
								>
									ベトナムへ配送開始
								</Button>
								<Button
									variant="success"
									onClick={() =>
										handlePushNotify(
											`notification-arrived-in-hanoi/${id}`,
										)
									}
								>
									ベトナム配送完了
								</Button>

							</div>
						</div>
					</div>
					<hr></hr>
				</div>
			</Form>
		</div>
	);
}

export default memo(FormInput);
