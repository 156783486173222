import { memo, useState } from 'react';
import { useParams } from 'react-router';
import { Form, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import productsAPI from '../../../../api/products';
import { ProtectedComponent } from '../../../../utils/Protected';
import orderAPI from '../../../../api/order';
import '../CardProducts.scss';

function FormLine4({ product, onCallApi }) {
	const [active, setActive] = useState(false);

	const { id } = useParams();

	const checkInput = text => {
		return text ? text : '';
	};

	const handleDelete = async () => {
		try {
			const res = await orderAPI.deleteProduct(id, { id: product._id });
			res && onCallApi();
			toast.info('Delete product success');
		} catch (err) {}
	};

	const handleSubmit = e => {
		e.preventDefault();
	};

	const handleCloseDialog = () => {
		setActive(false);
	};

	const handleChangeMethod = e => {
		const key = e.target.name;
		const value = e.target.value;

		(async () => {
			try {
				await productsAPI.update(product._id, { [key]: value });
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};

	return (
		<>
			<ProtectedComponent dependency={active}>
				<div className="overlay">
					<Modal.Dialog>
						<Modal.Header onClick={handleCloseDialog} closeButton>
							<Modal.Title>商品削除</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<p>商品を削除してもよろしいですか?</p>
						</Modal.Body>

						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={handleCloseDialog}
							>
								閉じる
							</Button>
							<Button variant="danger" onClick={handleDelete}>
								削除
							</Button>
						</Modal.Footer>
					</Modal.Dialog>
				</div>
			</ProtectedComponent>
			<Form onSubmit={handleSubmit}>
				<div className="card-line row">
					<div className="col col-lg-5">
						<Form.Label>お客さんからのメモ</Form.Label>
						<Form.Group className="mb-3">
							<Form.Control
								as="textarea"
								className="textarea-card"
								name="customerNote"
								onChange={handleChangeMethod}
								defaultValue={checkInput(product.customerNote)}
							/>
						</Form.Group>
					</div>
					<div className="col col-lg-5">
						<Form.Group className="mb-3">
							<Form.Label>スタッフ用のメモ</Form.Label>
							<Form.Control
								as="textarea"
								className="textarea-card"
								name="staffNote"
								onChange={handleChangeMethod}
								defaultValue={checkInput(product.staffNote)}
							/>
						</Form.Group>
					</div>
					<div className="col col-lg-2">
						<div className="group-btn--products">
							<button
								className="btn btn-danger"
								onClick={() => setActive(true)}
							>
								削除
							</button>
						</div>
					</div>
				</div>
			</Form>
		</>
	);
}

export default memo(FormLine4);
