import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { logout } from '../../../actions/auth';

function ButtonLogout() {
	const dispatch = useDispatch();
	return (
		<Button variant="secondary f-left" onClick={() => dispatch(logout())}>
			ログアウト
		</Button>
	);
}

export default ButtonLogout;
