import { memo, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { listStatus } from '../../../../constants/statusPack';

import packAPI from '../../../../api/pack';
import productsAPI from '../../../../api/products';
import '../CardProducts.scss';

function FormLine1({ product, no, onCallApi }) {
	const timerRef = useRef();
	const priceRef = useRef();
	const quantityRef = useRef();
	const ecShipFeeRef = useRef();
	const totalPrice = (product.price * product.quantity) + product.ecShipFee;
	const [list, setList] = useState([]);
	
	useEffect(() => {
		(async () => {
			try {
				// 登録済みのPACKを全て取得する
				const res = await packAPI.getAll(1, 100);
				if (res && res.status === 200) {
					setList([...res.data]);
				}
			} catch (err) {}
		})();
		return () => clearTimeout(timerRef.current);
	}, []);

	const checkInput = text => {
		return text ? text : '';
	};

	// 小計の計算処理
	const handleChangeMethod = e => {
		const key = e.target.name;
		const value = e.target.value;
		let data = {
			totalPrice:
				Number(quantityRef.current.value) * Number(priceRef.current.value)  + Number(ecShipFeeRef.current.value),
		};

		(async () => {
			try {
				await productsAPI.update(product._id, {
					...data,
					[key]: value,
				});
				timerRef.current = setTimeout(() => {
					onCallApi();
					clearTimeout(timerRef.current);
				}, 500);
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};
	
	return (
		<Form>
			<div className="title">
				<strong>商品情報</strong> <span>no.{no + 1}</span>
			</div>
			<div className="card-line row">
				<div className="col col-lg-8">
				</div>
				<div className="col col-lg-2">
					<Form.Label>PACK番号</Form.Label>
					<Form.Select
						aria-label="Default select example"
						name="packageId"
						onChange={e =>
							handleChangeMethod(e, `add-package/${product._id}`)
						}
						style={{marginBottom:"10px"}}
					>
						<option value={product?.pack?._id}>
							{product?.pack?.packNo || '梱包済みPACK番号'}
						</option>
						{list.map(item => (
							<option key={item._id} value={item._id}>
								{item.packNo}
							</option>
						))}
					</Form.Select>
				</div>
				<div className="col col-lg-2">
					<Form.Label>商品ステータス</Form.Label>
					<Form.Select
						aria-label="Default select example"
						name="status"
						onChange={handleChangeMethod}
					>
						<option value={product?.status}>
							{product?.status}
						</option>
						{listStatus.map(
							(item, index) =>
								index !== 0 && (
									<option
										key={index}
										value={item.value}
									>
										{item.text}
									</option>
								),
						)}
					</Form.Select>
				</div>
			</div>
			<div className="card-line row">
				<div className="col col-lg-5">
					<Form.Group className="mb-2">
						<Form.Label>商品名</Form.Label>
						<Form.Control
							type="text"
							name="name"
							onChange={handleChangeMethod}
							defaultValue={checkInput(product?.name)}
						/>
					</Form.Group>
				</div>
				<div className="col col-lg-3">
					<Form.Group className="mb-2">
						<Form.Label>商品URL　　　　</Form.Label>
						<a target="_blank" href={product.url}>商品ページ</a>
						<Form.Control
							type="text"
							name="url"
							onChange={handleChangeMethod}
							defaultValue={checkInput(product.url)}
						/>
					</Form.Group>
				</div>
				<div className="col col-lg-1">
					<Form.Group className="mb-2">
						<Form.Label>数量</Form.Label>
						<Form.Control
							ref={quantityRef}
							type="text"
							name="quantity"
							onChange={handleChangeMethod}
							defaultValue={checkInput(
								product?.quantity,
							)}
						/>
					</Form.Group>
				</div>
				<div className="col col-lg-1">
					<Form.Group className="mb-2">
						<Form.Label>単価(JPY)</Form.Label>
						<Form.Control
							ref={priceRef}
							type="text"
							name="price"
							onChange={handleChangeMethod}
							defaultValue={checkInput(
								product?.price,
							)}
						/>
					</Form.Group>
				</div>
				<div className="col col-lg-1">
					<Form.Group className="mb-2">
						<Form.Label>EC送料(JPY)</Form.Label>
						<Form.Control
							ref={ecShipFeeRef}
							type="text"
							name="ecShipFee"
							onChange={handleChangeMethod}
							defaultValue={checkInput(
								product?.ecShipFee,
							)}
						/>
					</Form.Group>
				</div>
				<div className="col col-lg-1">
					<Form.Group className="mb-2">
						<Form.Label>小計(JPY)</Form.Label>
						<Form.Control
							type="text"
							readOnly
							name="totalPrice"
							onChange={handleChangeMethod}
							value={checkInput(totalPrice)}
						/>
					</Form.Group>
				</div>
			</div>
		</Form>
	);
}

export default memo(FormLine1);
