import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Routers from './routers';

import SideBar from './views/components/SideBar';
import ScrollToTop from './utils/ScrollToTop';
import { ProtectedComponent } from './utils/Protected';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	const { isLogged } = useSelector(state => state.auth);
	return (
		<Router>
			<ToastContainer
				position="bottom-right"
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
			/>
			<ScrollToTop />
			<ProtectedComponent dependency={isLogged}>
				<SideBar />
			</ProtectedComponent>
			<Routers />
		</Router>
	);
}

export default App;
