import { memo } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

import {
	listStatus,
	methodDPayment,
	methodDeliveryVN,
} from '../../../../constants/statusPack';
import orderAPI from '../../../../api/order';

function FormSelect({ data }) {
	const { id } = useParams();

	const handleChangeMethod = e => {
		const key = e.target.name;
		const value = e.target.value;

		(async () => {
			try {
				const res = await orderAPI.update(id, { [key]: value });
				res && toast.success('Successfully');
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};

	return (
		<div>
			<Form>
				<div className="row">
				</div>
			</Form>
		</div>
	);
}

export default memo(FormSelect);
