export const listStatus = [
	{
		value: null,
		text: 'Status',
	},
	{
		value: '注文受付中',
		text: '注文受付中',
	},
	{
		value: 'キャンセル(ユーザー)',
		text: 'キャンセル(ユーザー)',
	},
	{
		value: 'キャンセル(Goaw)',
		text: 'キャンセル(Goaw)',
	},
	{
		value: '明細確認依頼中',
		text: '明細確認依頼中',
	},
	{
		value: '発注中',
		text: '発注中',
	},
	{
		value: '着荷済',
		text: '着荷済',
	},
	{
		value: '配送中',
		text: '配送中',
	},
	{
		value: 'ベトナム配送完了',
		text: 'ベトナム配送完了',
	},
	{
		value: '配達日時確認中',
		text: '配達日時確認中',
	},
	{
		value: '配達日時確定済',
		text: '配達日時確定済',
	},
	{
		value: 'ユーザー配送完了',
		text: 'ユーザー配送完了',
	},
	{
		value: '支払完了',
		text: '支払完了',
	},
];

export const methodDeliveryVN = [
	{
		value: null,
		text: 'Method Delivery in VN',
	},
	{
		value: '自宅まで配達',
		text: '自宅まで配達',
	},
	{
		value: '自分で引き取り',
		text: '自分で引き取り',
	},
];

export const methodDPayment = [
	{
		value: null,
		text: 'Method payment',
	},
	{
		value: '銀行振り込み（日本口座） ',
		text: '銀行振り込み（日本口座） ',
	},
	{
		value: '銀行振り込み（ベトナム口座）',
		text: '銀行振り込み（ベトナム口座）',
	},
	{
		value: '受取時に現金払い',
		text: '受取時に現金払い',
	},
];

export const packStatus = [
	{
		value: null,
		text: 'Status',
	},
	{
		value: '発送前',
		text: '発送前',
	},
	{
		value: '配送中',
		text: '配送中',
	},
	{
		value: 'ベトナム配送完了',
		text: 'ベトナム配送完了',
	},
];