import { memo, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';

import packAPI from '../../../../api/pack';
import productsAPI from '../../../../api/products';
import '../CardProducts.scss';

function FormLine2({ product, onCallApi }) {
	const timerRef = useRef();
	const [list, setList] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				// 登録済みのPACKを全て取得する
				const res = await packAPI.getAll(1, 100);
				if (res && res.status === 200) {
					setList([...res.data]);
				}
			} catch (err) {}
		})();
		return () => clearTimeout(timerRef.current);
	}, []);

	// 変更した際の非同期更新処理
	const handleChangeMethod = (e, id = product._id) => {
		const key = e.target.name;
		const value = e.target.value;
		let data = {};

		// 計量重量を変更した際は配送重量も更新する
		if (key === 'measurementWeight') {
			data = {
				fixedWeight: (Number(value) * 1.1).toFixed(2),
			};
		}

		(async () => {
			try {
				await productsAPI.update(id, { ...data, [key]: value });
				timerRef.current = setTimeout(() => {
					onCallApi();
					clearTimeout(timerRef.current);
				}, 1500);
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};

	const checkInput = text => {
		return text ? text : '';
	};

	return (
		<Form>
			<div className="card-line row">
				<div className="col col-lg-12">
					<div className="row">
						<div className="col col-lg-2">
							<Form.Group className="mb-2">
								<Form.Label>概算 重量</Form.Label>
								<Form.Control
									type="text"
									name="estimatedWeight"
									onChange={handleChangeMethod}
									defaultValue={checkInput(
										product.estimatedWeight,
									)}
								/>
							</Form.Group>
						</div>
						<div className="col col-lg-2">
							<Form.Group className="mb-2">
								<Form.Label>確定 重量</Form.Label>
								<Form.Control
									type="text"
									name="measurementWeight"
									onChange={handleChangeMethod}
									defaultValue={checkInput(
										product.measurementWeight,
									)}
								/>
							</Form.Group>
						</div>
						<div className="col col-lg-2">
							<Form.Group className="mb-2">
								<Form.Label>配送 重量　※計量重量の1.1倍</Form.Label>
								<Form.Control
									type="text"
									name="fixedWeight"
									readOnly
									value={Number(
										checkInput(
											product.measurementWeight * 1.1,
										),
									).toFixed(2)}
								/>
							</Form.Group>
						</div>
						<div className="col col-lg-2">
							<Form.Group className="mb-2">
								<Form.Label>ECサイトからの着荷予定</Form.Label>
								<Form.Control
									type="text"
									name="estimateArriveFromEcSite"
									onChange={handleChangeMethod}
									defaultValue={checkInput(
										product.estimateArriveFromEcSite,
									)}
								/>
							</Form.Group>
						</div>
						<div className="col col-lg-2">
							<Form.Group className="mb-2">
								<Form.Label>配送先</Form.Label>
								<Form.Control
									type="text"
									name="shipCompany"
									onChange={handleChangeMethod}
									defaultValue={checkInput(product.shipCompany)}
								/>
							</Form.Group>
						</div>
					</div>
				</div>
				
			</div>
		</Form>
	);
}

export default memo(FormLine2);
