import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
	listStatus,
	methodDPayment,
	methodDeliveryVN,
} from '../../../../constants/statusPack';
import { NavLink } from 'react-router-dom';
import orderAPI from '../../../../api/order';
import { Form } from 'react-bootstrap';
import styles from '../AdminInfoOrder.module.scss';

function FormInput2({ data }) {
	const { id } = useParams();
	const [listHistoryNotify, setListHistoryNotify] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const res = await orderAPI.getAllNotify(id);
				if (res.status === 200) {
					console.log(res.data);
					setListHistoryNotify(res.data);
				}
			} catch (err) {}
		})();
	}, [id]);

	const checkInput = text => {
		return text ? text : '';
	};

	const handleChangeMethod = e => {
		const key = e.target.name;
		const value = e.target.value;

		(async () => {
			try {
				await orderAPI.update(id, { [key]: value });
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};

	const showDate = date => {
		const dateNew = new Date(date);

		return `${dateNew.toLocaleDateString(
			'en-GB',
		)} - ${dateNew.getHours()}:${dateNew.getMinutes()}`;
	};

	return (
		<div className="mt-3">
			<Form>
				<div className="row">
					<div className="col col-lg-8">
						<div className="row">
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>依頼主_お客様名</Form.Label>
									<Form.Control
										type="text"
										name="name"
										onChange={handleChangeMethod}
										defaultValue={checkInput(data.name)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>依頼主_メールアドレス</Form.Label>
									<Form.Control
										type="text"
										onChange={handleChangeMethod}
										name="email"
										defaultValue={checkInput(data.email)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>依頼主_電話番号</Form.Label>
									<Form.Control
										type="text"
										onChange={handleChangeMethod}
										name="phone"
										defaultValue={checkInput(data.phone)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>Line名/Google名</Form.Label>
									<Form.Control
										type="text"
										defaultValue={checkInput(
											data?.user?.name,
										)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>配達先_お客様名</Form.Label>
									<Form.Control
										type="text"
										name="shippingName"
										onChange={handleChangeMethod}
										defaultValue={checkInput(data.shippingName)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>配達先_メールアドレス</Form.Label>
									<Form.Control
										type="text"
										onChange={handleChangeMethod}
										name="shippingEmail"
										defaultValue={checkInput(data.shippingEmail)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>配達先_電話番号</Form.Label>
									<Form.Control
										type="text"
										onChange={handleChangeMethod}
										name="shippingPhone"
										defaultValue={checkInput(data.shippingPhone)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>明細書URL　　　　　　</Form.Label>
									<NavLink to={checkInput(data.url)}>
										明細を開く
									</NavLink>
									<Form.Control
										type="text"
										onChange={handleChangeMethod}
										name="url"
										defaultValue={checkInput(data.url)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>認証方法</Form.Label>
									<Form.Control
										type="text"
										readOnly
										name="url"
										defaultValue={checkInput(
											data?.user?.social,
										)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-6">
								<Form.Group className="mb-3">
									<Form.Label>配達先住所</Form.Label>
									<Form.Control
										type="text"
										onChange={handleChangeMethod}
										name="shippingAddress"
										defaultValue={checkInput(
											data.shippingAddress,
										)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
							<Form.Group className="mb-3">
									<Form.Label>注文経路</Form.Label>
									<Form.Control
										type="text"
										readOnly
										name="orderRoute"
										defaultValue={checkInput(data.orderRoute)}
									/>
								</Form.Group>
							</div>

							<div className="col col-lg-3">
								<Form.Label>配達方法</Form.Label>
								<Form.Select
									name="deliveryMethod"
									aria-label="Default select example"
									onChange={handleChangeMethod}
								>
									{methodDeliveryVN.map(
										(item, index) =>
											index !== 0 && (
												<option
													key={index}
													value={item.value}
													selected={item.value == data.deliveryMethod}
												>
													{item.text}
												</option>
											),
									)}
								</Form.Select>
							</div>
							<div className="col col-lg-3">
								<Form.Label>支払い方法</Form.Label>
								<Form.Select
									name="paymentMethod"
									aria-label="Default select example"
									onChange={handleChangeMethod}
									className={styles.marginBottom20}
								>
									{methodDPayment.map(
										(item, index) =>
											index !== 0 && (
												<option
													key={index}
													value={item.value}
													selected={item.value == data.paymentMethod}
												>
													{item.text}
												</option>
											),
									)}
								</Form.Select>
							</div>
						</div>
					</div>
					<div className=" col col-lg-4">
						<Form.Label>PUSH通知送信履歴</Form.Label>
						<div className={styles.listNotify}>
							{listHistoryNotify.map(item => (
								<div
									className={styles.itemNotify}
									key={item._id}
								>
									<p>
										{showDate(item.updated_at)}
										:<br />
										{item.type}
									</p>
								</div>
							))}
						</div>
					</div>
					<hr></hr>

				</div>
			</Form>
		</div>
	);
}

export default memo(FormInput2);
