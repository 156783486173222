import { memo } from 'react';

import imgSpin from '../../../assets/images/spiner.svg';
import Image from '../Image';

function LoadingData({ active }) {
	return (
		<>
			{active && (
				<div style={{ width: '100px', margin: '0 auto' }}>
					<Image src={imgSpin} alt={'spinner'} />
				</div>
			)}
		</>
	);
}

export default memo(LoadingData);
