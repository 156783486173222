import React, { useEffect, useState } from 'react';

import ButtonLogout from '../../components/ButtonLogOut';
import { useDateEmpty } from '../../../hooks/useDate';
import { shipType } from '../../../constants/orderConst';
import orderAPI from '../../../api/order';
import MainPage from '../../components/MainPage';
import { NavLink } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import LoadingData from '../../components/LoadingData';
import styles from './AdminOrder.module.scss';

function AdminOrderPage() {
	const dateEmpty = useDateEmpty;
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const res = await orderAPI.getAll();
				if (res && res.status === 200) {
					setLoading(false);
					setList([...res.data]);
				}
			} catch (err) {}
		})();
	}, []);

	return (
		<MainPage>
			<section>
				<ButtonLogout />
				<div className={styles.title}>
					<h1>注文一覧</h1>
				</div>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>注文番号</th>
							<th>注文日</th>
							<th>依頼主_お客様名</th>
							<th>配達先_お客様名</th>
							<th>配達先_電話番号</th>
							<th>住所</th>
							<th>配送代行/購入代行</th>
							<th>支払い方法</th>
							{/* <th>配達希望日</th> */}
							{/* <th>配達希望時間</th> */}
							<th>注文ステータス</th>
						</tr>
					</thead>
					<tbody>
						{list.map(item => (
							<tr key={item._id}>
								<td>
									<NavLink to={`/order/${item._id}`}>
										{item.orderNo}
									</NavLink>
								</td>
								<td>{dateEmpty(item.orderDate)}</td>
								<td>{item.name}</td>
								<td>{item.shippingName}</td>
								<td>{item.shippingPhone}</td>
								<td>{item.shippingAddress}</td>
								<td>{shipType[item.orderType]}</td>
								<td>{item.paymentMethod}</td>
								{/* <td>{dateEmpty(item.deliveryDate)}</td> */}
								{/* <td>{item.deliveryTime}</td> */}
								<td>{item.status}</td>
							</tr>
						))}
					</tbody>
				</Table>
				<LoadingData active={loading} />
			</section>
		</MainPage>
	);
}

export default AdminOrderPage;
