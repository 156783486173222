import { memo } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';


import productsAPI from '../../../../api/products';
import '../CardProducts.scss';

function FormLine3({ product }) {
	const checkInput = text => {
		return text ? text : '';
	};

	const handleChangeMethod = (e, id = product._id) => {
		const key = e.target.name;
		const value = e.target.value;
		(async () => {
			try {
				await productsAPI.update(id, { [key]: value });
			} catch (err) {
				toast.error('Error!');
			}
		})();
	};

	return (
		<Form>
			<div className="card-line row">
				
				
			</div>
		</Form>
	);
}

export default memo(FormLine3);
