import { memo, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router';

import orderAPI from '../../../../api/order';
import { Form } from 'react-bootstrap';
import styles from '../AdminInfoOrder.module.scss';

function Info({ data = [], infoOrder }) {
	
	const { id } = useParams();
	const [extraShipFee, setExtraShipFee] = useState(infoOrder.extraShipFee);
	const [domesticShipFee, setDomesticShipFee] = useState(infoOrder.DomesticShipFee);

	const isNumber = value => {
		return typeof value === 'number' && isFinite(value);
	};

	const checkInput = text => {
		return text ? Number(text).toLocaleString('ja-JP') : 0;
	};

	// 追加費用を数値で返却する
	const extraShipFeeValue = useCallback(
		value => {
			return isNumber(Number(value))
				? Number(value)
				: infoOrder.extraShipFee;
		},
		[infoOrder.extraShipFee],
	);

	// 国内送料を数値で返却する
	const domesticShipFeeValue = useCallback(
		value => {
			return isNumber(Number(value))
				? Number(value)
				: infoOrder.domesticShipFee;
		},
		[infoOrder.domesticShipFee],
	);

	// 合計金額　225は固定円→ドンレート レート変更2022/08/01-
	const totalPrice = useMemo(
		() => data.reduce((a, b) => a + b.totalPrice, 0) * 200,
		[data],
	);
	// 
	const totalEstimatedWeight = useMemo(
		() => data.reduce((a, b) => a + b.estimatedWeight, 0),
		[data],
	);

	const totalFixedWeight = useMemo(
		() => data.reduce((a, b) => a + b.fixedWeight, 0),
		[data],
	);

	const estimatedShipfee = useMemo(
		() => data.reduce((a, b) => a + b.estimatedWeight, 0) * 400000,
		[data],
	);
	const fixedShipfee = useMemo(
		() => data.reduce((a, b) => a + b.fixedWeight, 0) * 400000,
		[data],
	);

	// 概算　お支払い合計　千ドン以下の端数は切り上げ
	const totalEstimatedFee = useMemo(
		() => Math.ceil(Number(totalPrice + estimatedShipfee + extraShipFeeValue(extraShipFee) + domesticShipFeeValue(domesticShipFee)) / 10000) * 10000,
		[estimatedShipfee, totalPrice, extraShipFee, extraShipFeeValue, domesticShipFee],
	);

	// 確定　お支払い合計　千ドン以下の端数は切り上げ
	const totalFixedFee = useMemo(
		() => Math.ceil(Number(totalPrice + fixedShipfee + extraShipFeeValue(extraShipFee) + domesticShipFeeValue(domesticShipFee)) / 10000) * 10000,
		[totalPrice, fixedShipfee, extraShipFee, extraShipFeeValue, domesticShipFee],
	);

	const handleChangeMethod = e => {
		const key = e.target.name;
		const value = e.target.value;

		(async () => {
			try {
				await orderAPI.update(id, { [key]: value });
			} catch (err) {}
		})();
	};

	return (
		<div className="mt-3">
			<Form>
				<div className="row">
					<div className="col col-lg-8">
						<div className="row">
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>商品価格合計</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											totalPrice
										) + " VND"}
									/>
								</div>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>追加費用(VND)</Form.Label>
									<Form.Control
										type="number"
										name="extraShipFee"
										defaultValue={infoOrder.extraShipFee}
										onChange={e => {
											handleChangeMethod(e);
											setExtraShipFee(
												Number(e.target.value),
											);
										}}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
								<Form.Group className="mb-3">
									<Form.Label>国内送料(VND)</Form.Label>
									<Form.Control
										type="number"
										name="domesticShipFee"
										defaultValue={infoOrder.domesticShipFee}
										onChange={e => {
											handleChangeMethod(e);
											setDomesticShipFee(
												Number(e.target.value),
											);
										}}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-3">
							</div>
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>概算 合計重量</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											totalEstimatedWeight
										) + " kg"}
									/>
								</div>
							</div>
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>概算 送料</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											estimatedShipfee
										) + " VND"}
									/>
								</div>
							</div>
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>概算 お支払い合計</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											totalEstimatedFee
										) + " VND"}
									/>
								</div>
							</div>
							<div className="col col-lg-3">
							</div>
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>確定 合計重量</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											totalFixedWeight
										) + " kg"}
									/>
								</div>
							</div>
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>確定 送料</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											fixedShipfee
										) + " VND"}
									/>
								</div>
							</div>
							
							<div className="col col-lg-3">
								<div className="mb-3">
									<Form.Label>確定 お支払い合計</Form.Label>
									<Form.Control
										type="text"
										readOnly
										value={checkInput(
											totalFixedFee
										) + " VND"}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col col-lg-4">
						<div className="mb-3">
							<Form.Label>スタッフ用の注文のメモ</Form.Label>
							<Form.Control
								as="textarea"
								name="note"
								onChange={handleChangeMethod}
								className={styles.textArea}
								defaultValue={infoOrder.note}
							/>
						</div>
					</div>
				</div>
			</Form>
		</div>
	);
}

export default memo(Info);
