import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { useDateConvert } from '../../../hooks/useDate';
import packAPI from '../../../api/pack';
import { listStatus, packStatus } from '../../../constants/statusPack';
import MainPage from '../../components/MainPage';
import styles from './AdminUpdatePack.module.scss';
import LoadingData from '../../components/LoadingData';
import { ProtectedComponent } from '../../../utils/Protected';

function AdminUpdatePackPage() {
	const dateConvert = useDateConvert;
	const { id } = useParams();
	const [form, setForm] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const res = await packAPI.getDetial(id);
				setLoading(false);
				res && setForm(res.data);
			} catch (err) {}
		})();
	}, [id]);

	const handleChange = e => {
		const key = e.target.name;
		const value = e.target.value;
		setForm({ ...form, [key]: value });
	};

	const handleSubmit = e => {
		(async () => {
			try {
				const res = await packAPI.update(id, form);
				if (res.status === 200) {
					toast.success('情報を更新しました。');
				}
			} catch (err) {}
		})();
	};

	return (
		<MainPage>
			<LoadingData active={loading} />
			<ProtectedComponent dependency={!loading}>
				<section>
					<Form>
						<div className="row">
							<div className="col col-lg-2">
								<Form.Group className="mb-3">
									<Form.Label>Pack no</Form.Label>
									<Form.Control
										required
										type="text"
										name="packNo"
										onChange={handleChange}
										value={form.packNo}
									/>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>海外送料</Form.Label>
									<Form.Control
										required
										type="text"
										name="overseaFee"
										onChange={handleChange}
										value={form.overseaFee}
									/>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>配送日</Form.Label>
									<Form.Control
										required
										type="text"
										name="startShipDate"
										onChange={handleChange}
										value={dateConvert(form.startShipDate)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-2">
								<Form.Group className="mb-3">
									<Form.Label>合計金額</Form.Label>
									<Form.Control
										required
										type="text"
										name="totalPrice"
										onChange={handleChange}
										value={form.totalPrice}
									/>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>日本国内送料</Form.Label>
									<Form.Control
										required
										type="text"
										name="jpDomesticFee"
										onChange={handleChange}
										value={form.jpDomesticFee}
									/>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>到着日</Form.Label>
									<Form.Control
										required
										type="text"
										name="arrivedDate"
										onChange={handleChange}
										value={dateConvert(form.arrivedDate)}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-2">
								<Form.Group className="mb-3">
									<Form.Label>合計重量</Form.Label>
									<Form.Control
										required
										type="text"
										name="totalWegiht"
										onChange={handleChange}
										value={form.totalWegiht}
									/>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label>ベトナム国内送料</Form.Label>
									<Form.Control
										required
										type="text"
										name="vnDomesticFee"
										onChange={handleChange}
										value={form.vnDomesticFee}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-4">
								<Form.Group className="mb-3">
									<Form.Control
										required
										as="textarea"
										name="note"
										onChange={handleChange}
										value={form.note}
										className={styles.textArea}
									/>
								</Form.Group>
							</div>
							<div className="col col-lg-2">
								<Form.Select
									required
									name="status"
									onChange={handleChange}
									aria-label="Default select example"
								>
									<option value={form.status}>
										{form.status}
									</option>
									{packStatus.map(item => (
										<option
											key={item.text}
											value={item.value}
										>
											{item.text}
										</option>
									))}
								</Form.Select>
							</div>
						</div>
						<div className={styles.groupBtn}>
							<Button
								className={styles.submit}
								variant="primary"
								onClick={handleSubmit}
							>
								保存
							</Button>
						</div>
					</Form>
				</section>
			</ProtectedComponent>
		</MainPage>
	);
}

export default AdminUpdatePackPage;
