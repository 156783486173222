import { memo } from 'react';

import CardProducts from '../CardProducts';

function ListProduct({ list, onCallApi }) {
	return (
		<div>
			{list.map((product, index) => (
				<CardProducts
					onCallApi={onCallApi}
					key={product._id}
					product={product}
					no={index}
				/>
			))}
		</div>
	);
}

export default memo(ListProduct);
