export const useDateConvert = date => {
	const newDate = new Date(date);
	const convert = `${newDate.getFullYear()}-${
		newDate.getMonth() + 1
	}-${newDate.getDate()}`;

	return convert === 'Invalid Date' ? '' : convert;
};

// 日付のチェック処理
export const useDateEmpty = date => {
	const newDate = new Date(date);
	const convert = newDate.toLocaleDateString('ja-JP');

	return convert === 'Invalid Date' ? '' : convert;
};
