import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import orderAPI from '../../../api/order';
import MainPage from '../../components/MainPage';
import FormInput from './components/FormInput';
import FormInput2 from './components/FormInput2';
import FormSelect from './components/FormSelect';
import Info from './components/Info';
import ListProduct from '../../components/ListProduct';
import styles from './AdminInfoOrder.module.scss';
import { ProtectedComponent } from '../../../utils/Protected';
import LoadingData from '../../components/LoadingData';

function AdminInfoOrderPage() {
	const { id } = useParams();
	const [listProsuct, setListProduct] = useState([]);
	const [infoOrder, setInfoOrder] = useState({});
	const [loading, setLoading] = useState(true);

	const callAPI = useCallback(async () => {
		try {
			const res = await orderAPI.getDetial(id);
			if (res && res.status === 200) {
				if (res.data.products) {
					setLoading(false);
					setInfoOrder(res.data);
					setListProduct(res.data.products);
				}
			}
		} catch (err) {}
	}, [id]);

	const handleAddProduct = useCallback(async () => {
		try {
			const res = await orderAPI.addNew(id);
			if (res && res.status === 200) {
				callAPI();
				toast.success('New products');
			} else {
				toast.error('Please try again');
			}
		} catch (err) {
			toast.error('Something went wrong');
		}
	}, [id, callAPI]);

	useEffect(() => {
		callAPI();
	}, [callAPI]);

	return (
		<MainPage>
			<ProtectedComponent dependency={!loading}>
				<section>
					<div className={styles.form}>
						<FormInput data={infoOrder} />
						<FormInput2 data={infoOrder} />
						<FormSelect data={infoOrder} />
						<Info data={listProsuct} infoOrder={infoOrder} />
					</div>
					<ProtectedComponent dependency={listProsuct.length > 0}>
						<div>
							<ListProduct
								list={listProsuct}
								onCallApi={callAPI}
							/>
						</div>
					</ProtectedComponent>
					<Button onClick={handleAddProduct}>商品追加</Button>
				</section>
			</ProtectedComponent>
			<LoadingData active={loading} />
		</MainPage>
	);
}

export default AdminInfoOrderPage;
