import axiosClient from '.';

import { getItemStorage } from '../utils/localStorage';
const routeName = '/order';

const orderAPI = {
	getAll: tokenAxios => {
		const url = `${routeName}`;
		return axiosClient.get(url, {
			cancelToken: tokenAxios,
			headers: {
				Authorization: 'Bearer ' + getItemStorage('accessToken'),
			},
		});
	},
	getDetial: (id, tokenAxios) => {
		const url = `${routeName}/${id}`;
		return axiosClient.get(url, {
			cancelToken: tokenAxios,
			headers: {
				Authorization: 'Bearer ' + getItemStorage('accessToken'),
			},
		});
	},
	pushNotify: (role, tokenAxios) => {
		const url = `${routeName}/${role}`;
		return axiosClient.put(url, {}, {
			cancelToken: tokenAxios,
			headers: {
				Authorization: 'Bearer ' + getItemStorage('accessToken'),
			},

		});
	},
	getAllNotify: (id, tokenAxios) => {
		const url = `${routeName}/history-notfication/${id}`;
		return axiosClient.get(url, {
			cancelToken: tokenAxios,
			headers: {
				Authorization: 'Bearer ' + getItemStorage('accessToken'),
			},
		});
	},
	update: (id, data, tokenAxios) => {
		const url = `${routeName}/${id}`;
		return axiosClient.put(
			url,
			{ ...data },
			{
				cancelToken: tokenAxios,
				headers: {
					Authorization: 'Bearer ' + getItemStorage('accessToken'),
				},
			},
		);
	},
	deleteProduct: (id, data, tokenAxios) => {
		const url = `${routeName}/delete-product/${id}`;
		return axiosClient.put(
			url,
			{ ...data },
			{
				cancelToken: tokenAxios,
				headers: {
					Authorization: 'Bearer ' + getItemStorage('accessToken'),
				},
			},
		);
	},
	addNew: (id, data, tokenAxios) => {
		const url = `${routeName}/add-product/${id}`;
		return axiosClient.post(
			url,
			{ ...data },
			{
				cancelToken: tokenAxios,
				headers: {
					Authorization: 'Bearer ' + getItemStorage('accessToken'),
				},
			},
		);
	},
};

export default orderAPI;
